import { useFlags } from 'launchdarkly-react-client-sdk';

export interface FeatureFlagValues {
  allowFullPurchasePartnerPrivileges: boolean;
  showRawResourcesView: boolean;
  showPoolSelector: boolean;
  showLinerUi: boolean;
  dealerDistributor: boolean;
}

const defaultValues: FeatureFlagValues = {
  allowFullPurchasePartnerPrivileges: false,
  showRawResourcesView: false,
  showPoolSelector: false,
  showLinerUi: false,
  dealerDistributor: false,
};

export const useFeatureFlags = () =>
  (useFlags() as FeatureFlagValues) ?? defaultValues;
