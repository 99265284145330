import { AnchorsFormType } from '../components/orders/Forms/Partials/Anchors/AnchorsForm/AnchorsFormSchema';
import { AccessoriesFormPropTypes } from '../components/orders/Forms/Partials/Accessories/AccessoriesFormSchema';
import { FeaturesFormPropTypes } from '../components/orders/Forms/Partials/Features/FeaturesForm/FeaturesFormSchema';
import { ShippingFormPropTypes } from 'components/orders/Forms/Partials/Shipping/ShippingFormSchema';
import { Brand } from '../components/orders/Forms/Partials/Product/ProductFormSchema';
import { ProjectFormPropTypes } from 'components/orders/Forms/Partials/Project/ProjectFormSchema';
import { specOrderState } from 'context/OrderContext';
import { SpecFormPropTypes } from 'components/orders/Forms/Partials/Spec/SpecFormSchema';
import { DataFromSchema, createModel } from './Model';

export const LEGAL_TERMS_URL =
  'https://latham-cloud-api.s3.us-west-2.amazonaws.com/docs/NoPumpOrDrain.002.2.8.22.pdf';

export enum OrderType {
  Cover = 'Safety Cover',
  Liner = 'Vinyl Liner',
}

export enum CoverType {
  Cover = 'Safety Cover',
  Other = 'Other',
}

export enum ReplacementType {
  New = 'New',
  Replacement = 'Replacement',
}

export enum OrderStatus {
  Editing = 'editing',
  Saved = 'Saved',
  QuotedOptions = 'Quoted with Options',
  ReadyForOrder = 'Ready for Order',
  QuoteInReview = 'Quote in Review',
  OrderInReview = 'Order in Review',
  Ordered = 'Ordered',
  Delivered = 'Delivered',
  Completed = 'Completed',
  Deleted = 'Deleted',
}

export interface LathamLinkResponse {
  erpOrderResponse: string;
  linkOrderNumber: string;
  quoteNumber: string;
  quoteOrderUrl: string;
}

export enum OrderQuoteStatus {
  Valid = 'valid',
  Declined = 'declined',
  Expired = 'expired',
}

export interface OrderQuote {
  expiredAt: Date | string;
  id: string;
  lathamLinkResponse: LathamLinkResponse;
  quotedAt: Date | string;
  requestedBy: string;
  status: OrderQuoteStatus;
}

export interface ServerProduct {
  newOrReplacement: 'Replacement Cover' | 'New Cover';
  partner: string;
  brand: Brand;
  gridSpacing: '5x5' | '3x3' | 'Non Standard, Anchor Match';
  material: 'Solid' | 'Mesh';
  materialOptions: string;
  bottomMaterialOptions: string;
  linerPattern: string;
  linerBottomPattern: string;
  millGauge: string;
  bottomMillGauge: string;
  bead: string;
  fabric:
    | '1000HS_Full-Weight-Solid'
    | '500P_Lite-Solid'
    | '5000M_Standard-Mesh'
    | '7000MS_High-Shade-Mesh'
    | '9000MX_Max-Shade-Mesh'
    | 'Quote all options';
  color: string; //@todo types for this
  drainOrPump: 'Invis-a-Drain' | 'Pump' | 'No, thanks';
  commercialSprings: null | boolean;
  pumpQty: string;
  noDrainOrPumpAck: boolean;
}

interface ServerFeature {
  label: string;
  anchor: string;
  cableAssembly: boolean;
}

interface ServerDeck {
  label: string;
  decking: string;
}

export interface ServerAccessories {
  doubleD_Rings: boolean;
  yStraps: boolean;
  yStrapQty: number;
  strapLength: number;
  topAndBottomWebbing: boolean;
  buckles: boolean;
  kemkap: boolean;
}

export interface OrderBody {
  product: ServerProduct;
  project?: ProjectFormPropTypes;
  features: FeaturesFormPropTypes;
  anchors: AnchorsFormType;
  accessories: AccessoriesFormPropTypes;
  shipping: ShippingFormPropTypes;
  lathamSpec?: SpecFormPropTypes;
  dealerId: string;
  projectId: string;
  status: OrderStatus;
  quoteId?: string;
  quotes?: OrderQuote[];
  poNumber?: string;
  type?: string;
  requestedByEmail?: string;
  requestedByUserId?: string;
  requestedByUserName?: string;
  priceOption?: string;
}

export interface PoolDetails {
  surfaceArea?: number;
  lengthIn?: number;
  widthIn?: number;
  surfacePerimeter?: number;
  poolShapeType?: PoolShapeType;
  poolShape?: PoolShape;
  poolShapeBottom: PoolShapeBottom;
}

export enum PoolShapeType {
  Geometric = 'geometric',
  Freeform = 'freeform',
}

export interface PoolShape {
  geometric: GeometricPoolShape;
  freeform: FreeformPoolShape;
}

export enum GeometricPoolShape {
  Rectangle = 'Rectangle',
  Oval = 'Oval',
  Round = 'Round',
  TrueL = 'True L',
  LazyL = 'Lazy L',
  Grecian = 'Grecian',
  Octagon = 'Octagon',
  RomanEnd = 'Roman End',
  ModifiedOval = 'Modified Oval',
  GrecianTrueL = 'Grecian True L',
  GrecianLazyL = 'Grecian Lazy L',
  Patrician = 'Patrician',
  Rio = 'Rio',
  Highlander = 'Highlander',
  Kite = 'Kite',
  Keyhole = 'Keyhole',
  MonteCarloJazz = 'Monte Carlo / Jazz',
  NorlanderCapeMay = 'Norlander / Cape May',
  FreeFormDigSpecs = 'Free Form (Dig Specs)',
  TrueLLeft = 'True L Left',
  LazyLLeft = 'Lazy L Left',
  GrecianTrueLLeft = 'Grecian True L Left',
  GrecianLazyLLeft = 'Grecian Lazy L Left',
  RioLeft = 'Rio Left',
  HighlanderLeft = 'Highlander Left',
  NorlanderCapeMayLeft = 'Norlander / Cape May Left',
  TrueLRight = 'True L Right',
  LazyLRight = 'Lazy L Right',
  GrecianTrueLRight = 'Grecian True L Right',
  GrecianLazyLRight = 'Grecian Lazy L Right',
  RioRight = 'Rio Right',
  HighlanderRight = 'Highlander Right',
  NorlanderCapeMayRight = 'Norlander / Cape May Right',
  FiberglassFreeform = 'Fiberglass Freeform',
  FiberglassGeometric = 'Fiberglass Geometric',
}

export enum FreeformPoolShape {
  Kidney = 'Kidney',
  OtherPointtopoint = 'Other(Point to point)',
  Figure8 = 'Figure 8',
  MountainCrystalLake = 'Mountain/Crystal Lake',
  ObliqueCelebrity = 'Oblique/Celebrity',
  GeminiOmniOasisCypress = 'Gemini/Omni/Oasis/Cypress',
  PacificLagoon = 'Pacific Lagoon',
  MountainPond = 'Mountain Pond',
  MichiganSentra = 'Michigan/Sentra',
  Taormina = 'Taormina',
  PaletteNautilus = 'Palette/Nautilus',
  MontereyNiagraVermont = 'Monterey/Niagra/Vermont',
  Odyssey = 'Odyssey',
  CancunBlues = 'Cancun/Blues',
  Diamond = 'Diamond',
  Escale = 'Escale',
  Liberty = 'Liberty',
  FreeformCustom = 'Freeform (Custom)',
  KidneyLeft = 'Kidney Left',
  MountainCrystalLakeLeft = 'Mountain/Crystal Lake Left',
  ObliqueCelebrityLeft = 'Oblique/Celebrity Left',
  GeminiOmniOasisCypressLeft = 'Gemini/Omni/Oasis/Cypress Left',
  PacificLagoonLeft = 'Pacific Lagoon Left',
  MountainPondLeft = 'Mountain Pond Left',
  MichiganSentraLeft = 'Michigan/Sentra Left',
  TaorminaLeft = 'Taormina Left',
  PaletteNautilusLeft = 'Palette/Nautilus Left',
  MontereyNiagraVermontLeft = 'Monterey/Niagra/Vermont Left',
  OdysseyLeft = 'Odyssey Left',
  CancunBluesLeft = 'Cancun/Blues Left',
  DiamondLeft = 'Diamond Left',
  EscaleLeft = 'Escale Left',
  LibertyLeft = 'Liberty Left',
  KidneyRight = 'Kidney Right',
  MountainCrystalLakeRight = 'Mountain/Crystal Lake Right',
  ObliqueCelebrityRight = 'Oblique/Celebrity Right',
  GeminiOmniOasisCypressRight = 'Gemini/Omni/Oasis/Cypress Right',
  PacificLagoonRight = 'Pacific Lagoon Right',
  MountainPondRight = 'Mountain Pond Right',
  MichiganSentraRight = 'Michigan/Sentra Right',
  TaorminaRight = 'Taormina Right',
  PaletteNautilusRight = 'Palette/Nautilus Right',
  MontereyNiagraVermontRight = 'Monterey/Niagra/Vermont Right',
  OdysseyRight = 'Odyssey Right',
  CancunBluesRight = 'Cancun/Blues Right',
  DiamondRight = 'Diamond Right',
  EscaleRight = 'Escale Right',
  LibertyRight = 'Liberty Right',
  MonteCarloJazzRight = 'Monte Carlo / Jazz Right',
}

export enum PoolShapeBottom {
  TBD = 'to-be-defined-post-MVP',
}

export interface ServerOrder extends OrderBody {
  status: OrderStatus;
  id: string;
  createdAt: string;
  updatedAt: string;
  poolDetails: PoolDetails;
  requestedBy?: string;
  poNumber?: string;
  type?: string;
  archived?: boolean;
  lathamSpec?: specOrderState;
  priceOption?: string;
}

export const PriceOptionOptions = [
  { label: 'Net Price', value: 'Net Price' },
  { label: 'List Price', value: 'List Price' },
];

const required = true;

const OrderSchema = {
  createdAt: { type: 'date', label: 'Created Date' },
  updatedAt: { type: 'date', label: 'Updated Date' },
  projectId: { type: 'text', label: 'Project', required },
  dealerId: { type: 'text', label: 'Dealer', required },
  poNumber: { type: 'text', label: 'PO Number' },
  type: { type: 'text', label: 'Order Type' },
  status: { type: 'text', label: 'Status', required },
} as const;

const OrderSchemaModel = {
  createdAt: { type: 'date', label: 'Created Date' },
  updatedAt: { type: 'date', label: 'Updated Date' },
  projectName: { type: 'text', label: 'Project', required },
  dealerName: { type: 'text', label: 'Dealer', required },
  poNumber: { type: 'text', label: 'PO Number' },
  type: { type: 'text', label: 'Order Type' },
  status: { type: 'text', label: 'Status', required },
} as const;
// ☝️ this const assertion is needed for DataFromSchema generic to do its magic

export type Order = DataFromSchema<typeof OrderSchema> &
  OrderBody &
  AddedOrderBody;

export interface AddedOrderBody {
  projectName?: string;
  dealerName?: string;
}

export const OrderModel = createModel<Order>(OrderSchemaModel);
