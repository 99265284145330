import React, { useState } from 'react';
import OrderForm, { GenericFieldValidationType } from '../../OrderForm';
import {
  ProjectFormFieldsErrorMessageType,
  ProjectFormFieldsValidationType,
  ProjectFormPropTypes,
  ProjectFormSchemaForOrder,
  ProjectFormSchemaForOrderNoPo,
  ProjectFormSchemaForQuote,
  ProjectFormSchemaForQuoteMustHavePriceOption,
} from './ProjectFormSchema';
import './ProjectForm.scss';
import { ManagedInput } from 'components/orders/Fields/Input/ManagedInput';
import { OrderContext } from '../../../../../context/OrderContext';
import { OrderStatus, OrderType } from 'models';
import { SpecFormPropTypes } from '../Spec/SpecFormSchema';
import { ManagedSelect } from 'components/orders/Fields/Select/ManagedSelect';
import { productTypeSelectOptions } from '../Product/ProductFormConstants';
import { useFeatureFlags } from 'services';
import { getLathamSpec } from 'components/orders/helpers/getLathamSpec';
import { PriceOptionOptions } from 'models/Order';

interface ProjectFormProps {
  data: ProjectFormPropTypes;
  hasEditAccess: boolean;
}

/**
 * All fields are "valid", until we get an error!
 */
const InitialProjectFormValidationStatus =
  (): ProjectFormFieldsValidationType => {
    return {
      address1: true,
      address2: true,
      city: true,
      shippingState: true,
      postalCode: true,
      primaryContactName: true,
      poNumber: true,
      type: true,
      priceOption: true,
    };
  };

const ProjectForm = (props: ProjectFormProps) => {
  // Global state
  const { state, dispatch } = React.useContext(OrderContext);
  const flags = useFeatureFlags();

  const isAfterQuote =
    state.order?.status === OrderStatus.QuotedOptions ||
    state.order?.status === OrderStatus.ReadyForOrder;
  const disabled = state?.order?.archived || !props?.hasEditAccess;

  const disableQuoteAndOrderForVinylLiner =
    (state.project?.poolShape?.baseName ?? state.project?.poolShapeType) ===
      'Fiberglass Geometric' ||
    (state.project?.poolShape?.baseName ?? state.project?.poolShapeType) ===
      'Fiberglass Freeform';

  const [type, setType] = useState<OrderType | undefined>(
    (flags.showLinerUi && !disableQuoteAndOrderForVinylLiner
      ? state.order?.type ?? props.data?.type
      : OrderType.Cover) as OrderType
  );

  // The po number field should be hidden during the quote phase
  const showPoNumber = isAfterQuote;

  // The po number field should be optional if the field is not shown or if its after quote and not a dealer direct order
  const optionalPoNumber =
    (isAfterQuote && state.product.partner !== 'Dealer Direct') ||
    !showPoNumber;

  const [poNumber, setPoNumber] = useState<string | undefined>(
    props.data.poNumber ?? state.project?.poNumber
  );

  // props.data comes from the project so we want to get that address first, if it's empty get the address from the state
  const [address1, setAddress1] = useState<string | undefined>(
    state.project?.address1 === ''
      ? props.data.address1
      : state.project?.address1
  );
  const [address2, setAddress2] = useState<string | undefined>(
    state.project?.address2 === ''
      ? props.data.address2
      : state.project?.address2
  );
  const [city, setCity] = useState<string | undefined>(
    state.project?.city === '' ? props.data.city : state.project?.city
  );
  const [shippingState, setShippingState] = useState<string | undefined>(
    state.project?.shippingState === ''
      ? props.data.shippingState
      : state.project?.shippingState
  );
  const [postalCode, setPostalcode] = useState<string | undefined>(
    state.project?.postalCode === ''
      ? props.data.postalCode
      : state.project?.postalCode
  );
  const [primaryContactName, setPrimaryContactName] = useState<
    string | undefined
  >(
    state.project?.primaryContactName === ''
      ? props.data.primaryContactName
      : state.project?.primaryContactName
  );

  const [showPriceOption, setShowPriceOption] = useState<boolean | undefined>(
    state.project.isDistributorDealer && flags.dealerDistributor
  );

  const orderPriceOption = state.order?.priceOption ?? undefined;
  const projectPriceOption = state.project?.priceOption;

  const [priceOption, setPriceOption] = useState<string | undefined>(
    projectPriceOption === 'No need to choose' ||
      projectPriceOption === undefined
      ? orderPriceOption
      : projectPriceOption
  );

  // Validation state
  const [validFields, setValidFields] =
    useState<ProjectFormFieldsValidationType>(
      InitialProjectFormValidationStatus()
    );

  const [errorMessages, setErrorMessages] =
    useState<ProjectFormFieldsErrorMessageType>({ poNumber: null });

  const currentValues = {
    address1,
    address2,
    city,
    shippingState,
    postalCode,
    primaryContactName,
    poNumber,
    type,
    priceOption,
  };
  const lathamSpec = getLathamSpec(state);
  const specVals = {
    ...lathamSpec,
    projectAttributes: {
      ...lathamSpec?.projectAttributes,
      poNumber: poNumber ?? '',
    },
  } as SpecFormPropTypes;

  const updateOrderState = () => {
    // console.log(currentValues);
    dispatch({
      type: 'updateProject',
      payload: currentValues,
    });
    dispatch({
      type: 'updateSpec',
      payload: specVals,
    });
    dispatch({
      type: 'updateOrder',
      payload: {
        ...state.order,
        priceOption: priceOption ?? undefined,
      },
    });
  };

  return (
    <div className="project-form">
      <div className="project-form__title">Order Details</div>
      <OrderForm
        values={currentValues}
        initialValidationState={InitialProjectFormValidationStatus()}
        schema={
          isAfterQuote
            ? optionalPoNumber
              ? ProjectFormSchemaForOrderNoPo
              : ProjectFormSchemaForOrder
            : showPriceOption
            ? ProjectFormSchemaForQuoteMustHavePriceOption
            : ProjectFormSchemaForQuote
        }
        onFieldsValid={updateOrderState}
        onFieldsInvalid={updateOrderState}
        setValidFields={
          setValidFields as React.Dispatch<
            React.SetStateAction<GenericFieldValidationType>
          >
        }
        setFieldErrorMessages={setErrorMessages}
        fieldErrorMessages={errorMessages}
      >
        <>
          {flags.showLinerUi && !disableQuoteAndOrderForVinylLiner && (
            <ManagedSelect
              label="Product Type?"
              isValid={validFields.type}
              value={type}
              onChange={setType}
              options={productTypeSelectOptions}
              name="product_type"
              disabled={
                disabled || state.order?.status === OrderStatus.ReadyForOrder
              }
            />
          )}
          {showPriceOption && (
            <ManagedSelect
              label="Price Option"
              isValid={validFields.priceOption}
              value={priceOption}
              onChange={setPriceOption}
              options={PriceOptionOptions}
              name="priceOption"
              disabled={
                disabled || state.order?.status === OrderStatus.ReadyForOrder
              }
            />
          )}
          {showPoNumber && (
            <ManagedInput
              errorMessage={
                errorMessages.poNumber !== 'PO Number is a required field'
                  ? errorMessages.poNumber
                  : ''
              }
              label="PO Number"
              isValid={validFields.poNumber}
              type="text"
              placeholder="PO Number"
              onChange={setPoNumber}
              value={poNumber}
              disabled={disabled}
            />
          )}
          <div className="project-form__second-title">
            <div className="project-form__title">Project Address</div>
            <>
              <ManagedInput
                label="Address"
                isValid={validFields.address1}
                type="text"
                placeholder="Address"
                onChange={setAddress1}
                value={address1}
                disabled={disabled}
              />
              <ManagedInput
                label="Address (cont.)"
                isValid={validFields.address2}
                type="text"
                placeholder="Address (cont.)"
                onChange={setAddress2}
                value={address2}
                disabled={disabled}
              />
              <ManagedInput
                label="City"
                isValid={validFields.city}
                type="text"
                placeholder="City"
                onChange={setCity}
                value={city}
                disabled={disabled}
              />
              <div className="shipping-form__two-column">
                <ManagedInput
                  label="State"
                  isValid={validFields.shippingState}
                  type="text"
                  placeholder="State"
                  onChange={setShippingState}
                  value={shippingState}
                  disabled={disabled}
                />
                <ManagedInput
                  label="ZIP"
                  errorMessage={
                    errorMessages.postalCode !==
                    'postalCode is a required field'
                      ? errorMessages.postalCode
                      : ''
                  }
                  isValid={validFields.postalCode}
                  type="text"
                  placeholder="ZIP"
                  onChange={setPostalcode}
                  value={postalCode}
                  disabled={disabled}
                />
              </div>
              <ManagedInput
                label="Primary Contact Name"
                isValid={validFields.primaryContactName}
                type="text"
                placeholder="Primary Contact Name"
                onChange={setPrimaryContactName}
                value={primaryContactName}
                disabled={disabled}
              />
            </>
          </div>
        </>
      </OrderForm>
    </div>
  );
};

export default ProjectForm;
